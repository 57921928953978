<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from 'vue-multiselect';
import { VMoney } from 'v-money';

import UserService from "@/services/user";

export default {
  page: {
    title: "Prestadores de Serviço",
  },
  components: { Layout, PageHeader, Multiselect },
  directives: { money: VMoney },
  data() {
    return {
      title: "Prestadores de Serviço",
      providers: [],

      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      showMoreFilters: true,
      searchValue: '',
      searchService: '',
      searchCity: '',
      searchState: '',
      searchBankDataStatus: '',
      searchProviderStatus: '',

      services: [],
      states: [],
      bankDataStatus: [],
      providerStatus: [],

      newProviderData: {
        email: '',
        cpf: '',
        name: '',
        serviceType: '',
        serviceValue: 0,
      },
      newProviderError: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      }
    }
  },
  computed: {
    rows() {
      return this.providers.length;
    }
  },
  methods: {
    loadProviders(page = 1) {
      const loader = this.$loading.show();

      const params = this.getSearchParams();

      UserService.getProviders(page, params).then(res => {
        this.providers = res.data.providers;

        this.count = res.data.count
        this.currentCount = res.data.currentCount
        this.currentPage = res.data.currentPage
        this.totalPages = res.data.totalPages
        this.perPage = res.data.perPage
        this.startIndex = res.data.startIndex
        this.endIndex = res.data.endIndex

        loader.hide();
      }).catch(() => {
        loader.hide();
      })
    },
    onPageChange() {
      this.loadProviders(this.currentPage);
    },
    search() {
      this.loadProviders();
    },
    clearFilters() {
      this.searchValue = '';
      this.searchService = '';
      this.searchCity = '';
      this.searchState = '';
      this.searchBankDataStatus = '';
      this.searchProviderStatus = this.providerStatus[0];
    },
    exportData() {
      const loader = this.$loading.show();

      const params = this.getSearchParams();
      params.export = true;

      UserService.getProviders(null, params).then(res => {
        loader.hide();

        const fileURL = window.URL.createObjectURL(new Blob([res.data]));
        let fileLink = document.createElement('a');

        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'lista-prestadores.csv');
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(() => {
        loader.hide();
      })
    },
    blockUser(provider) {
      const loader = this.$loading.show();

      UserService.blockProvider(provider._id).then(() => {
        loader.hide();

        this.$toast.open('Prestador bloqueado com sucesso');
        this.loadProviders(this.currentPage);
        this.$bvModal.hide('modal-new-provider');  
      }).catch(() => {
        loader.hide();
      })
    },
    unblockUser(provider) {
      const loader = this.$loading.show();

      UserService.unblockProvider(provider._id).then(() => {
        loader.hide();

        this.$toast.open('Prestador desbloqueado com sucesso');
        this.loadProviders(this.currentPage);
        this.$bvModal.hide('modal-new-provider');  
      }).catch(() => {
        loader.hide();
      })
    },
    getSearchParams() {
      const params = {};

      if (this.searchValue) {
        params.term = this.searchValue;
      }

      if (this.searchService) {
        params.service = this.searchService._id;
      }

      if (this.searchCity) {
        params.city = this.searchCity;
      }

      if (this.searchState) {
        params.state = this.searchState;
      }

      if (this.searchBankDataStatus) {
        params.bankDataStatus = this.searchBankDataStatus.status;
      }

      if (this.searchProviderStatus) {
        params.providerStatus = this.searchProviderStatus.status;
      }

      return params;
    },
    newProvider() {
      this.newProviderError = '';
      this.newProviderData = {
        email: '',
        cpf: '',
        name: '',
        serviceType: '',
        serviceValue: 0,
      };

      this.$bvModal.show('modal-new-provider');
    },
    confirmNewProvider() {
      this.newProviderError = '';

      const loader = this.$loading.show();

      UserService.createProvider(this.newProviderData).then(() => {
        loader.hide();

        this.$toast.open('Prestador cadastrado com sucesso');
        this.loadProviders(1);
        this.$bvModal.hide('modal-new-provider');  
      }).catch(err => {
        loader.hide();

        const errorData = err.response.data;
        if (errorData.code) {
          if (errorData.code === 'E9999') {
            return this.newProviderError = errorData.msg;
          }
        }

        this.newProviderError = 'Ocorreu um erro ao salvar a contratação';
      })
    },
  },
  mounted() {
    this.loadProviders();

    UserService.getServices().then(res => {
      this.services = res.data;
    })

    this.states = ['', 'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

    this.bankDataStatus = [
      { status: 'approved', description: 'Aprovado' },
      { status: 'analysis', description: 'Em Análise' },
      { status: 'rejected', description: 'Rejeitado' },
      { status: 'pending', description: 'Pendente' },
      { status: 'none', description: 'Não Informada' },
    ]

    this.providerStatus = [
      { status: 'all', description: 'TODOS' },
      { status: 'active', description: 'Ativos' },
      { status: 'inactive', description: 'Inativos' },
      { status: 'blocked', description: 'Bloqueados' },
    ]

    this.searchProviderStatus = this.providerStatus[0];
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col">
        <div class="card mb-0">
          <div class="card-body">
            <form @submit.prevent="search" class="align-items-center direction-column">
              <div class="row">
                <div class="col-5">
                  <label class="mb-1">Nome ou CPF</label>
                  <input type="text" class="form-control" v-model="searchValue" />
                </div>

                <div class="col col-3">
                  <label class="mb-1">Status</label>
                  <multiselect
                    v-model="searchProviderStatus"
                    :options="providerStatus"
                    placeholder=""
                    label="description"
                    track-by="status"
                    selectLabel=""
                    selectedLabel=""
                    deselectLabel=""
                    :close-on-select="true"
                  ></multiselect>
                </div>

                <div class="col-4 search-users">
                  <button type="submit" class="btn btn-rounded btn-primary button-large">
                    Pesquisar
                  </button>

                  <button class="btn btn-rounded btn-outline-primary ml-2" @click="exportData">
                    Exportar CSV
                  </button>
                </div>
              </div>

              <div class="row" v-if="showMoreFilters">
                <div class="col-8">
                  <div class="row mt-3">
                    <div class="col col-5">
                      <label class="mb-1">Serviço Oferecido</label>
                      <multiselect
                        v-model="searchService"
                        :options="services"
                        label="description"
                        track-by="_id"
                        placeholder=""
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                      ></multiselect>
                    </div>

                    <div class="col col-5">
                      <label class="mb-1">Cidade</label>
                      <input type="text" class="form-control" v-model="searchCity" />
                    </div>

                    <div class="col col-2">
                      <label class="mb-1">UF</label>
                      <multiselect
                        v-model="searchState"
                        :options="states"
                        placeholder=""
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                        :close-on-select="true"
                        :searchable="true"
                      ></multiselect>
                    </div>

                    <!-- <div class="col col-3">
                      <label class="mb-1">Status</label>
                      <multiselect
                        v-model="searchBankDataStatus"
                        :options="bankDataStatus"
                        placeholder=""
                        label="description"
                        track-by="status"
                        selectLabel=""
                        selectedLabel=""
                        deselectLabel=""
                        :close-on-select="true"
                      ></multiselect>
                    </div> -->
                  </div>
                </div>
              </div>
            </form>

            <div class="row mt-3 mb-0 bottom-buttons">
              <b-button variant="link" @click="clearFilters" class="mr-2">Limpar Filtros</b-button>

              <button class="btn btn-rounded btn-outline-success ml-2" @click="newProvider">
                Novo Prestador
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row mt-0">
      <div class="col-lg-12"> 
        <div class="table-responsive">
          <table class="table project-list-table table-centered table-borderless">
            <thead>
              <tr>               
                <th scope="col">Nome</th>
                <!-- <th scope="col">CPF</th> -->
                <th scope="col">Telefone</th>
                <th scope="col">Cidade/UF</th>
                <th scope="col">Serviços Oferecidos</th>
                <th scope="col">Valor Diária</th>
                <th scope="col">Status</th>
                <th scope="col">Data do Cadastro</th>
                <th scope="col">Última Contratação</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="provider in providers" :key="provider._id">
                <td>
                  <div class="team">
                    <a href="javascript: void(0);" class="team-member d-inline-block" v-if="provider.picture">
                      <img :src="provider.picture" class="rounded-circle avatar-xs m-1 mr-2" alt />
                    </a>
                    
                    <div class="rounded-circle avatar-xs m-1 mr-2 avatar-letter" v-if="!provider.picture">{{provider.name.toUpperCase().substring(0,1)}}</div>
                    <!-- {{provider.name}} -->

                    <div class="ml-1">
                      {{provider.name}}
                      <p class="text-muted font-size-11 m-0">{{provider.cpf}}</p>
                    </div>
                  </div>
                </td>
                <!-- <td>{{provider.cpf}}</td> -->
                <td>{{provider.phoneNumber}}</td>
                <td>{{provider.cityDescription}}</td>
                <td>{{provider.services}}</td>
                <td>{{provider.dailyValue}}</td>
                <!-- <td>
                  <span class="badge badge-pill font-size-12" :class="{
                    'badge-warning': `${provider.bankDataStatus}` === 'analysis',
                    'badge-success': `${provider.bankDataStatus}` === 'approved',
                    'badge-soft-secondary': `${provider.bankDataStatus}` === 'none' || `${provider.bankDataStatus}` === 'pending',
                    'badge-soft-danger': `${provider.bankDataStatus}` === 'rejected',
                  }"
                  >{{provider.bankDataStatusDescription}}</span>
                </td> -->
                <td>
                  <span class="badge badge-pill font-size-12" :class="{
                    'badge-warning': (provider.inactive && !provider.blocked),
                    'badge-danger': provider.blocked,
                    'badge-success': (!provider.inactive && !provider.blocked)
                  }"
                  >{{provider.statusDescription}}</span>
                </td>
                <td>{{new Date(provider.createdAt) | dateFormat('DD/MM/YYYY HH:mm')}}</td>
                <td v-if="provider.lastHiring">{{ new Date(provider.lastHiring) | dateFormat('DD/MM/YYYY HH:mm') }}</td>
                <td v-else>-</td>
                <td>
                  <b-dropdown class="card-drop" variant="white" right toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>
                    <router-link tag="a" :to="'/provider/' + provider._id">
                      <b-dropdown-item href="javascript: void(0);">
                        <i class="fas fa-edit text-primary mr-2 mt-2"></i>
                        Editar
                      </b-dropdown-item>
                    </router-link>

                    <b-dropdown-divider></b-dropdown-divider>

                    <b-dropdown-item href="javascript: void(0);" v-if="!provider.blocked" @click="blockUser(provider)">
                      <i class="fas fa-lock text-danger mr-2 mt-2"></i>
                      Bloquear Usuário
                    </b-dropdown-item>

                    <b-dropdown-item href="javascript: void(0);" v-if="provider.blocked" @click="unblockUser(provider)">
                      <i class="fas fa-unlock text-success mr-2 mt-2"></i>
                      Desbloquear Usuário
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="row justify-content-md-between align-items-md-center">
      <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>

      <div class="col-xl-5">
        <div class="text-md-right float-xl-right mt-2 pagination-rounded">
          <b-pagination
            v-model="currentPage" 
            :total-rows="count"
            :per-page="perPage"
            @input="onPageChange"
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-new-provider"
      title="Novo Prestador"
      title-class="font-18"
      centered
    >
      <b-alert show dismissible v-if="newProviderError" variant="danger">{{newProviderError}}</b-alert>

      <div class="row">
        <div class="col-12">
          <div class="form-group mb-3">
            <label for="name">Nome</label>
            <input id="name" name="name" type="text" class="form-control" v-model="newProviderData.name" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group mb-3">
            <label for="cpf">CPF</label>
            <input id="cpf" name="cpf" type="text" class="form-control" v-model="newProviderData.cpf" v-mask="'###.###.###-##'" />
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-3">
            <label for="email">E-mail</label>
            <input id="email" name="email" type="text" class="form-control" v-model="newProviderData.email" />
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col col-6">
          <label class="mb-1">Serviço Oferecido</label>
          <select class="form-control" v-model="newProviderData.serviceType"> 
            <option v-for="service in services" :key="service._id" :value="service._id">{{service.description}}</option>
          </select>
        </div>

        <div class="col col-6">
          <label class="mb-1">Valor da Diária</label>
          <input
            id="serviceValue"
            class="form-control"
            v-model="newProviderData.serviceValue"
            v-money="money"
          />
        </div>
      </div>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-new-provider')" class="mr-3">Cancelar</b-btn>
        <b-btn variant="primary" @click="confirmNewProvider">Cadastrar Prestador</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .team {
    display: flex;
    align-items: center;    
  }

  .avatar-letter {
    height: 32px;
    width: 32px;
    min-height: 32px;
    min-width: 32px;
    background-color: rgb(241, 101, 73, 0.3);
    color: #F16549;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
  }

  .search-users {
    display: flex;
    align-items: flex-end;

    .button-large {
      flex-grow: 1;
    }
  }

  .more-filters .multiselect__tags {
    border-radius: 30px;
  }

  .form-control {
    height: 38px;
  }

  .bottom-buttons {
    display: flex;
    justify-content: space-between;
  }
</style>